import activityReducerObject from "./reducers/activityReducer";
import authenticationReducerObject from "./reducers/authenticationReducer";
// Reducers
import complianceReducerObject from "./reducers/complianceReducer";
import documentViewerReducerObject from "./reducers/documentViewerReducer";
import explosivesPermitReducerObject from "./reducers/explosivesPermitReducer";
import externalAuthorizationReducerObject from "./reducers/externalAuthorizationReducer";
import incidentReducerObject from "./reducers/incidentReducer";
import mineReducerObject from "./reducers/mineReducer";
import minespaceReducerObject from "./reducers/minespaceReducer";
import modalReducerObject from "./reducers/modalReducer";
import noticeOfDepartureReducerObject from "./reducers/noticeOfDepartureReducer";
import noticeOfWorkReducerObject from "./reducers/noticeOfWorkReducer";
import orgbookReducerObject from "./reducers/orgbookReducer";
import partiesReducerObject from "./reducers/partiesReducer";
import permitReducerObject from "./reducers/permitReducer";
import projectReducerObject from "./reducers/projectReducer";
import reportReducerObject from "./reducers/reportReducer";
import searchReducerObject from "./reducers/searchReducer";
import securitiesReducerObject from "./reducers/securitiesReducer";
import staticContentReducerObject from "./reducers/staticContentReducer";
import tailingsReducerObject from "./reducers/tailingsReducer";
import varianceReducerObject from "./reducers/varianceReducer";
import workInformationReducerObject from "./reducers/workInformationReducer";
import verifiableCredentialReducerObject from "./reducers/verifiableCredentialReducer";

export const complianceReducer = complianceReducerObject;
export const authenticationReducer = authenticationReducerObject;
export const incidentReducer = incidentReducerObject;
export const workInformationReducer = workInformationReducerObject;
export const mineReducer = mineReducerObject;
export const minespaceReducer = minespaceReducerObject;
export const modalReducer = modalReducerObject;
export const documentViewerReducer = documentViewerReducerObject;
export const noticeOfWorkReducer = noticeOfWorkReducerObject;
export const partiesReducer = partiesReducerObject;
export const permitReducer = permitReducerObject;
export const reportReducer = reportReducerObject;
export const searchReducer = searchReducerObject;
export const staticContentReducer = staticContentReducerObject;
export const varianceReducer = varianceReducerObject;
export const securitiesReducer = securitiesReducerObject;
export const orgbookReducer = orgbookReducerObject;
export const explosivesPermitReducer = explosivesPermitReducerObject;
export const externalAuthorizationReducer = externalAuthorizationReducerObject;
export const projectReducer = projectReducerObject;
export const noticeOfDepartureReducer = noticeOfDepartureReducerObject;
export const activityReducer = activityReducerObject;
export const tailingsReducer = tailingsReducerObject;
export const verifiableCredentialReducer = verifiableCredentialReducerObject;
