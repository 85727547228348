// Misc
export const AUTHENTICATION = "AUTHENTICATION";
export const STATIC_CONTENT = "STATIC_CONTENT";
export const MODAL = "MODAL";
export const DOCUMENT_VIEWER = "DOCUMENT_VIEWER";
export const SYSTEM_FLAG = "SYSTEM_FLAG";

// Mines
export const MINES = "MINES";

// Parties
export const PARTIES = "PARTIES";

// OrgBook
export const ORGBOOK = "ORGBOOK";

// Compliance Orders
export const COMPLIANCE = "COMPLIANCE";

// MineSpace
export const MINESPACE = "MINESPACE";

// Permits
export const PERMITS = "PERMITS";

// Explosive Storage & Use Permits
export const EXPLOSIVES_PERMITS = "EXPLOSIVES_PERMITS";

// Search
export const SEARCH = "SEARCH";

// Variances
export const VARIANCES = "VARIANCES";

// Projects
export const PROJECTS = "PROJECTS";
export const PROJECT_SUMMARIES = "PROJECT_SUMMARIES";
export const INFORMATION_REQUIREMENTS_TABLE = "INFORMATION_REQUIREMENTS_TABLE";

// Core Users
export const USERS = "USERS";

// Incidents
export const INCIDENTS = "INCIDENTS";

// Work Information
export const MINE_WORK_INFORMATIONS = "MINE_WORK_INFORMATIONS";

// Reports
export const REPORTS = "REPORTS";

// Notices of Work
export const NOTICE_OF_WORK = "NOTICE_OF_WORK";

// Securities
export const SECURITIES = "SECURITIES";

// EPIC Information
export const EXTERNAL_AUTHS = "EXTERNAL_AUTHS";

// Notices of Departure
export const NOTICES_OF_DEPARTURE = "NOTICES_OF_DEPARTURE";

// Activities
export const ACTIVITIES = "ACTIVITIES";

// Tailings Storage Facilities
export const TAILINGS = "TAILINGS";

//Verifiable Credentials
export const VERIFIABLE_CREDENTIALS = "VERIFIABLE_CREDENTIALS";
